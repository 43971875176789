import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { delay, mergeMap, Observable, of, retryWhen, throwError } from 'rxjs';
import { AppRoute } from '../../../shared/data-access/constants/app-route.constant';

const MAX_RETRY = 3;
const RETRY_DELAY = 2000;

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) => {
        return errors.pipe(
          mergeMap((err: HttpErrorResponse, attemptIdx: number) => {
            let errorMsg = '';

            switch (err.status) {
              case HttpStatusCode.Conflict:
              case HttpStatusCode.BadRequest:
              case HttpStatusCode.NotFound:
                return throwError(() => err);
              case HttpStatusCode.Forbidden:
                this.router.navigate([AppRoute.forbidden]);
                return throwError(() => err);
              default:
                if (err.error instanceof ErrorEvent) {
                  errorMsg = `Client-side error: ${err.error.message}`;
                } else {
                  errorMsg = `Server-side error: Status (${err.status}) | ${err.message}`;
                }
                console.error(`[ATTEMPT ${attemptIdx + 1}] ${errorMsg}`);

                if (attemptIdx + 1 === MAX_RETRY) {
                  return throwError(() => err);
                }
                return of(err);
            }
          }),
          delay(RETRY_DELAY)
        );
      })
    );
  }
}
export const httpErrorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};
