import { createReducer, on } from '@ngrx/store';
import { LookupsAPIActions } from './actions';
import { ILookupFields } from './entities/lookup-fields.entity';

export interface LookupDataState {
  lookupData: ILookupFields | null;
}

const initialState: LookupDataState = {
  lookupData: null,
};

export const lookupDataReducer = createReducer<LookupDataState>(
  initialState,
  on(LookupsAPIActions.updateLookupData, (state, action): LookupDataState => {
    return {
      ...state,
      lookupData: action.lookupData,
    };
  })
);
