import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DukeUser } from 'src/app/admin/data-access/models/duke-user.model';
import { AppConfig } from 'src/app/shared/app-config/app.config';
import { APP_CONFIG } from '../../app-config/app-config.token';

@Injectable({
  providedIn: 'root',
})
export class DukeUsersApiService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  getAll(): Observable<DukeUser[]> {
    return this.http.get<DukeUser[]>(`${this.appConfig.apiBaseURL}/duke-users`);
  }
}
