import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, shareReplay, tap } from 'rxjs';
import { DukeUser } from 'src/app/admin/data-access/models/duke-user.model';
import { DukeUsersAPIActions } from 'src/app/admin/dashboard/data-access/state/duke-users/actions';
import { DukeUsersApiService } from '../laps-api/duke-users-api';
import { AppState } from '../state/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class DukeUsersFacade {
  readonly dukeUsers$!: Observable<DukeUser[]>;

  constructor(
    private dukeUsersApiService: DukeUsersApiService,
    private store: Store<AppState>
  ) {
    this.dukeUsers$ = this.dukeUsersApiService.getAll().pipe(
      shareReplay(1),
      tap((dukeUsers: DukeUser[]) => {
        this.store.dispatch(
          DukeUsersAPIActions.updateDukeUserSuccess({
            dukeUsers,
          })
        );
      })
    );
  }
}
