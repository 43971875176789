import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, shareReplay, tap } from 'rxjs';
import { LookupDataService } from '../laps-api';
import { AppState } from '../state/app.reducer';
import { LookupsAPIActions } from '../state/lookup-data/actions';
import { ILookupFields } from '../state/lookup-data/entities/lookup-fields.entity';

@Injectable({
  providedIn: 'root',
})
export class LookupFacade {
  readonly lookupData$!: Observable<ILookupFields>;
  readonly adminLookupData$!: Observable<ILookupFields>;

  constructor(
    private lookupDataService: LookupDataService,
    private store: Store<AppState>
  ) {
    this.lookupData$ = this.getLookupData(false);
    this.adminLookupData$ = this.getLookupData(true);
  }

  private getLookupData(hasCustomFields: boolean): Observable<ILookupFields> {
    return this.lookupDataService.getLookupData(hasCustomFields).pipe(
      shareReplay(1),
      tap((lookupData: ILookupFields) =>
        this.store.dispatch(
          LookupsAPIActions.updateLookupData({
            lookupData,
          })
        )
      )
    );
  }
}
