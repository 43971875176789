import { createAction, props } from '@ngrx/store';
import { User } from '../user.entity';

export const setActiveUser = createAction(
  '[User Profile Page] Set Active User',
  props<{ user: User }>()
);

export const createUser = createAction(
  '[User Profile Page] Create User',
  props<{ user: User }>()
);

export const updateUser = createAction(
  '[User Profile Page] Update User',
  props<{ user: User }>()
);

export const clearActiveUser = createAction(
  '[User Profile Page] Clear Active User'
);
