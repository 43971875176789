import { createReducer, on } from '@ngrx/store';
import { Status } from 'src/app/shared/data-access/enums/load-status.enum';
import { DukeUser } from '../../../../data-access/models/duke-user.model';
import { DukeUsersAPIActions } from './actions';

export interface DukeUsersState {
  dukeUsers: DukeUser[];
  _ui: {
    status: Status;
    isSubmitting: boolean;
  };
}

const initialState: DukeUsersState = {
  dukeUsers: [],
  _ui: {
    status: Status.NOT_LOADED,
    isSubmitting: false,
  },
};

export const dukeUsersReducer = createReducer<DukeUsersState>(
  initialState,
  on(
    DukeUsersAPIActions.updateDukeUserSuccess,
    (state, action): DukeUsersState => {
      return {
        ...state,
        dukeUsers: action.dukeUsers,
        _ui: {
          status: Status.LOADED,
          isSubmitting: false,
        },
      };
    }
  ),
  on(DukeUsersAPIActions.updateDukeUserFail, (state): DukeUsersState => {
    return {
      ...state,
      _ui: {
        status: Status.NOT_LOADED,
        isSubmitting: true,
      },
    };
  })
);
