import { createAction, props } from '@ngrx/store';
import { User } from '../user.entity';

export const createUserSuccess = createAction(
  '[User API] Create User Success',
  props<{ user: User }>()
);

export const createUserFail = createAction('[User API] Create User Fail');

export const updateUserSuccess = createAction(
  '[User API] Update User Success',
  props<{ user: User }>()
);

export const updateUserFail = createAction('[User API] Update User Fail');
