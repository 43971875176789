import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, Observable, of, switchMap } from 'rxjs';
import { setActiveUser } from '../../../user/data-access/state/user/actions/profile-page.actions';
import { User } from '../../../user/data-access/state/user/user.entity';
import { UserFacade } from '../../../user/data-access/state/user/user.facade';
import { AppRoute } from '../../data-access/constants/app-route.constant';
import { AppState } from '../../data-access/state/app.reducer';
import { OverlaySpinnerService } from '../../ui/overlay-spinner/overlay-spinner.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private userFacade: UserFacade,
    private msalService: MsalService,
    private router: Router,
    private spinner: OverlaySpinnerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.spinner.show();
    return this.userFacade.user$.pipe(
      map((user: User) => {
        this.store.dispatch(setActiveUser({ user }));
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        let result: Observable<boolean | UrlTree>;

        if (this.getResolvedUrl(route) !== 'profile') {
          result = of(this.router.createUrlTree([AppRoute.profile]));
        } else {
          result = of(true);
        }

        if (error.status === HttpStatusCode.NotFound) {
          return this.userFacade.contractorAgreementURL$.pipe(
            switchMap((contractorAgreementURL: string) => {
              const user = User.fromAccountInfo(
                this.msalService.instance.getActiveAccount() ||
                  this.msalService.instance.getAllAccounts()[0]
              );
              user.contractorAgreementUrl = contractorAgreementURL;
              this.store.dispatch(setActiveUser({ user }));
              return result;
            })
          );
        }

        return result;
      }),
      finalize(this.spinner.hide)
    );
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    const segments = route.pathFromRoot.map((paths) =>
      paths.url.map((segment) => segment.toString())
    );
    return segments[segments.length - 1].join('');
  }
}
