export class AppRoute {
  static readonly root = '';
  static readonly forbidden = 'forbidden';
  static readonly unauthorized = 'unauthorized';
  static readonly msalAuth = 'auth';
  static readonly admin = {
    dashboard: 'dashboard',
    history: 'history',
    applications: 'applications',
    sites: 'sites',
    create: 'create',
    signatories: 'signatories',
    payments: 'payments',
  };

  static readonly applicant = {
    applications: 'applications',
    create: 'create',
    edit: ':id',
  };

  static readonly profile = 'profile';

  static get createApplication(): string {
    return `${this.applicant.applications}/${this.applicant.create}`;
  }

  static get editApplication(): string {
    return `${this.applicant.applications}/${this.applicant.edit}`;
  }

  static get adminApplications(): string {
    return `${this.admin.dashboard}/${this.admin.applications}`;
  }

  static get adminSites(): string {
    return `${this.admin.dashboard}/${this.admin.sites}`;
  }

  static get adminHistory(): string {
    return `${this.admin.dashboard}/${this.admin.history}`;
  }

  static get adminSitesCreate(): string {
    return `${this.admin.dashboard}/${this.admin.sites}/${this.admin.create}`;
  }
}
