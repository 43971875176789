import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';

import { environment } from 'src/environments/environment';

import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { MsalService } from '@azure/msal-angular';

import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
  constructor(private authService: MsalService) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T> | any> {
    if (this.isExternalAPI(req.url)) {
      return next.handle(req);
    }

    const accessTokenRequest = {
      scopes: [
        `openid profile email offline_access ${environment.azureB2C.clientId}`,
      ],
      account: this.authService.instance.getAllAccounts()[0],
    } as RedirectRequest;

    return this.authService.acquireTokenSilent(accessTokenRequest).pipe(
      switchMap(({ accessToken }: AuthenticationResult) => {
        if (!accessToken || accessToken === '') {
          return this.authService.instance.acquireTokenRedirect(
            accessTokenRequest
          );
        }

        const headers = req.headers.set(
          'Authorization',
          `Bearer ${accessToken}`
        );
        const authRequest = req.clone({
          headers,
        });

        return next.handle(authRequest);
      }),
      catchError((err) => {
        if (err.errorMessage?.includes('AADB2C90077')) {
          return this.authService.instance.acquireTokenRedirect(
            accessTokenRequest
          );
        }

        return throwError(() => err);
      })
    );
  }

  private isExternalAPI(requestURL: string): boolean {
    // TODO: DEFINE ALL OTHER EXTERNAL API ENDPOINTS
    const externalEndpoints = [
      environment.smarty.suggestionsEndpointURL,
      environment.smarty.coordinatesEndpointURL,
      environment.staticAssetsURL,
      environment.dirtyBucketURL,
    ];

    return externalEndpoints.some((endpoint: string) =>
      requestURL.includes(endpoint)
    );
  }
}

export const baseInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BaseHttpInterceptor,
  multi: true,
};
