<div mat-dialog-title class="!flex w-full text-lg justify-between">
  <h2 class="flex-auto !mb-0 !mt-1 text-blue-1000 align-bottom">
    {{ data.title }}
  </h2>
  <div class="flex-none inline-flex">
    <a
      role="button"
      class="bg-white-100 !min-w-fit"
      mat-icon-button
      (click)="closeModal()">
      <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
    </a>
  </div>
</div>
<div mat-dialog-content>
  <ng-container *ngTemplateOutlet="data.contentTemplate"></ng-container>
</div>
<div *ngIf="data.hasActions" mat-dialog-actions class="justify-center !pb-4">
  <button
    *ngIf="data.hasNoOption"
    mat-stroked-button
    color="primary"
    (click)="cancel()">
    {{ data.cancelText | uppercase }}
  </button>
  <button
    *ngIf="data.hasYesOption"
    mat-flat-button
    class="btn"
    color="primary"
    (click)="confirm()">
    {{ data.confirmText | uppercase }}
  </button>
</div>
