import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { AppRoute } from '../../data-access/constants/app-route.constant';
import { Role } from '../../data-access/enums/role.enum';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(private authService: MsalService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const roles = route?.data?.['roles'];

    if (!roles?.length) {
      return this.router.createUrlTree([AppRoute.unauthorized]);
    }

    return this.validateUserPageAccess(roles);
  }

  /**
   * Validates a user's access to a page based on the declared roles allowed for that page
   * @param {Role[]} allowedRoles - The list of the allowed roles for a certain page
   * @returns Observable<boolean | UrlTree> | boolean | UrlTree
   */
  private validateUserPageAccess(
    allowedRoles: Role[]
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.instance.getAllAccounts()[0];

    if (
      this.hasValidUserAdminAccess(allowedRoles, user) ||
      this.hasValidExternalUserAccess(allowedRoles, user)
    ) {
      return true;
    }

    if (this.isAdmin(user)) {
      return this.router.createUrlTree([AppRoute.admin.dashboard]);
    }

    return this.router.createUrlTree([AppRoute.applicant.applications]);
  }

  private hasValidUserAdminAccess(
    allowedRoles: Role[],
    user: AccountInfo
  ): boolean {
    return this.isAdmin(user) && allowedRoles.includes(Role.ADMIN);
  }

  private hasValidExternalUserAccess(
    allowedRoles: Role[],
    user: AccountInfo
  ): boolean {
    return !this.isAdmin(user) && allowedRoles.includes(Role.EXTERNAL);
  }

  /**
   * TODO: Call the laps-api to validate if the logged-in user
   * from Duke is eligible to access the app and does exist
   * from the Database - duke_users table
   */
  private isAdmin(accountInfo: AccountInfo): boolean {
    return !!(
      accountInfo?.idTokenClaims?.idp &&
      accountInfo?.idTokenClaims?.['idp_access_token']
    );
  }
}
