import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '../../app-config/app-config.token';
import { AppConfig } from '../../app-config/app.config';

import { ILookupFields } from '../state/lookup-data/entities/lookup-fields.entity';

@Injectable({
  providedIn: 'root',
})
export class LookupDataService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  /**
   * Returns all lookup data.
   * This should only be call once! from the resolver.
   * Why? Because this caches the whole response with
   * RXJS shareReplay operator.
   * @returns lookup fields object
   */
  getLookupData(hasCustomFields = false): Observable<ILookupFields> {
    return this.http.get<ILookupFields>(
      `${this.appConfig.apiBaseURL}/lookups`,
      {
        params: hasCustomFields
          ? new HttpParams().set(
              'fields',
              'siteStatus,lakes,counties,states,paymentDetailTypes'
            )
          : undefined,
      }
    );
  }
}
