import { createReducer, on } from '@ngrx/store';
import { Status } from 'src/app/shared/data-access/enums/load-status.enum';
import { StatusRule } from '../../../models/status-rules';
import {
  loadStatusRulesFailure,
  loadStatusRulesSuccess,
} from '../actions/status-rule.actions';

export const statusRuleFeatureKey = 'statusRule';

export interface StatusRuleState {
  statusRules: StatusRule[];
  _ui: {
    status: Status;
  };
}

export const initialState: StatusRuleState = {
  statusRules: [],
  _ui: {
    status: Status.NOT_LOADED,
  },
};

export const statusRulesReducer = createReducer(
  initialState,
  on(loadStatusRulesSuccess, (state, action): StatusRuleState => {
    return {
      ...state,
      statusRules: action.statusRules,
      _ui: { status: Status.LOADED },
    };
  }),
  on(loadStatusRulesFailure, (state): StatusRuleState => {
    return { ...state, _ui: { status: Status.NOT_LOADED } };
  })
);
