import { createReducer, on } from '@ngrx/store';
import { Status } from '../../../../shared/data-access/enums/load-status.enum';
import { ProfilePageActions, UserAPIActions } from './actions';
import { User } from './user.entity';

export interface UserState {
  activeUser: User | null;
  _ui: {
    status: Status;
    isSubmitting: boolean;
  };
}

const initialState: UserState = {
  activeUser: null,
  _ui: {
    status: Status.NOT_LOADED,
    isSubmitting: false,
  },
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(ProfilePageActions.setActiveUser, (state, action): UserState => {
    const activeUser =
      state._ui.status === Status.LOADED ? state.activeUser : action.user;

    return {
      ...state,
      activeUser,
      _ui: {
        status: Status.LOADED,
        isSubmitting: false,
      },
    };
  }),
  on(
    ProfilePageActions.createUser,
    ProfilePageActions.updateUser,
    (state): UserState => {
      return {
        ...state,
        _ui: {
          status: Status.NOT_LOADED,
          isSubmitting: true,
        },
      };
    }
  ),
  on(
    UserAPIActions.createUserSuccess,
    UserAPIActions.updateUserSuccess,
    (state, action): UserState => {
      const activeUser = { ...action.user };

      if (state.activeUser) {
        activeUser.contractorAgreementUrl =
          state.activeUser.contractorAgreementUrl;
      }

      return {
        ...state,
        activeUser,
        _ui: {
          status: Status.LOADED,
          isSubmitting: false,
        },
      };
    }
  ),
  on(
    UserAPIActions.createUserFail,
    UserAPIActions.updateUserFail,
    (state): UserState => {
      return {
        ...state,
        _ui: {
          status: Status.NOT_LOADED,
          isSubmitting: false,
        },
      };
    }
  ),
  on(ProfilePageActions.clearActiveUser, (state): UserState => {
    return {
      ...state,
      activeUser: null,
      _ui: {
        status: Status.NOT_LOADED,
        isSubmitting: false,
      },
    };
  })
);
