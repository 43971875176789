import { TemplateRef } from '@angular/core';

import { IConfirmModalCommonProps } from '../interfaces/confirm-modal-common-props';

export class ConfirmModalProperties {
  public title: string;
  public contentTemplate: TemplateRef<void> | null;
  public hasActions: boolean;
  public hasNoOption: boolean;
  public confirmText: string;
  public cancelText: string;
  public disableClose: boolean;
  public cancelOnClose: boolean;
  public hasYesOption?: boolean;
  public height?: string;
  public width?: string;
  public maxHeight?: string;
  public maxWidth?: string;
  public autoFocus?: boolean;

  constructor(
    contentTemplate: TemplateRef<void> | null,
    props: IConfirmModalCommonProps
  ) {
    this.contentTemplate = contentTemplate;
    this.title = props.title;
    this.hasActions = props.hasActions ?? true; // TODO: REMOVE WHEN SIMPLE MODAL COMP IS CREATED
    this.hasNoOption = props?.hasNoOption ?? true;
    this.hasYesOption = props?.hasYesOption ?? true;
    this.confirmText = props?.confirmText ?? 'Yes';
    this.cancelText = props?.cancelText ?? 'No';
    this.disableClose = props?.disableClose ?? false;
    this.cancelOnClose = props?.cancelOnClose ?? true;
    this.height = props.height;
    this.width = props.width;
    this.maxHeight = props.maxHeight;
    this.maxWidth = props.maxWidth;
    this.autoFocus = props.autoFocus;
  }
}
