import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import {
  dukeUsersReducer,
  DukeUsersState,
} from 'src/app/admin/dashboard/data-access/state/duke-users/duke-users.reducer';
import {
  statusRulesReducer,
  StatusRuleState,
} from 'src/app/admin/dashboard/data-access/state/status-rule/reducers/status-rule.reducer';
import {
  userReducer,
  UserState,
} from '../../../user/data-access/state/user/user.reducer';
import {
  lookupDataReducer,
  LookupDataState,
} from './lookup-data/lookup-data.reducer';

export interface AppState {
  router: RouterReducerState<any>;
  lookupData: LookupDataState;
  user: UserState;
  dukeUsers: DukeUsersState;
  statusRules: StatusRuleState;
}

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  lookupData: lookupDataReducer,
  user: userReducer,
  dukeUsers: dukeUsersReducer,
  statusRules: statusRulesReducer,
};
