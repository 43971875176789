import { AccountInfo } from '@azure/msal-browser';

export enum UserType {
  HOMEOWNER = 1,
  CONTRACTOR = 2,
  COMMON_OWNER = 3,
}

export interface IUserType {
  id: UserType;
  name?: string;
  description?: string;
}

export class User {
  id: number | null;
  email: string | null;
  firstName: string | null;
  middleName?: string | null;
  lastName: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  companyName?: string | null;
  primaryPhone: string | null;
  secondaryPhone?: string | null;
  secondaryEmail?: string | null;
  receiveEmail?: boolean;
  receiveText?: boolean;
  userType: IUserType;
  contractorAgreementUrl: string | null;
  isContractVerified: boolean;

  constructor() {
    this.id = null;
    this.email = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.address = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.companyName = null;
    this.primaryPhone = null;
    this.secondaryPhone = null;
    this.secondaryEmail = null;
    this.receiveEmail = false;
    this.receiveText = false;
    this.userType = {
      id: UserType.HOMEOWNER,
    };
    this.contractorAgreementUrl = null;
    this.isContractVerified = false;
  }

  static fromAccountInfo(accountInfo: AccountInfo | null): User {
    const user = new User();
    user.email = accountInfo?.idTokenClaims?.['emails']?.[0] as string;
    user.firstName = accountInfo?.idTokenClaims?.['given_name'] as string;
    user.lastName = accountInfo?.idTokenClaims?.['family_name'] as string;
    user.primaryPhone = accountInfo?.idTokenClaims?.[
      'extension_PhoneNumber'
    ] as string;
    return user;
  }
}
