import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  exhaustMap,
  finalize,
  map,
  of,
  tap
} from 'rxjs';
import { AppRoute } from '../../../../shared/data-access/constants/app-route.constant';
import { USER_TOASTER_CONFIG } from '../../../../shared/data-access/constants/user-toaster.config';
import { UserApiService } from '../../../../shared/data-access/laps-api';
import { IToasterConfig } from '../../../../shared/data-access/models/toaster-config.model';
import { ToasterService } from '../../../../shared/data-access/services/toaster/toaster.service';
import { OverlaySpinnerService } from '../../../../shared/ui/overlay-spinner/overlay-spinner.service';
import { ProfilePageActions, UserAPIActions } from './actions';
import { User } from './user.entity';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private spinner: OverlaySpinnerService,
    private toasterService: ToasterService,
    private userService: UserApiService
  ) {}

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePageActions.createUser),
      tap(this.spinner.show),
      exhaustMap((action) =>
        this.userService.upsert(action.user).pipe(
          map((user: User) => {
            return UserAPIActions.createUserSuccess({ user });
          }),
          catchError(() => of(UserAPIActions.createUserFail())),
          finalize(this.spinner.hide)
        )
      )
    )
  );

  createUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAPIActions.createUserSuccess),
        tap(() => this.onSuccess(USER_TOASTER_CONFIG.createUserSuccess))
      ),
    { dispatch: false }
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePageActions.updateUser),
      tap(this.spinner.show),
      concatMap((action) =>
        this.userService.upsert(action.user).pipe(
          map((user: User) => {
            return UserAPIActions.updateUserSuccess({ user });
          }),
          catchError(() => of(UserAPIActions.updateUserFail())),
          finalize(this.spinner.hide)
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAPIActions.updateUserSuccess),
        tap(() => this.onSuccess(USER_TOASTER_CONFIG.updateUserSuccess))
      ),
    { dispatch: false }
  );

  upsertUserFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAPIActions.createUserFail, UserAPIActions.updateUserFail),
        tap(() => this.onFail(USER_TOASTER_CONFIG.error))
      ),
    { dispatch: false }
  );

  private onFail(config: IToasterConfig): void {
    this.toasterService.showToaster(config);
  }

  private onSuccess(config: IToasterConfig): void {
    this.router.navigate([AppRoute.applicant.applications]);
    this.toasterService.showToaster(config);
  }
}
