import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

import {
  MsalBroadcastService,
  MsalCustomNavigationClient,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';

@Component({
  selector: 'laps-root',
  template: `
    <main>
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </main>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  destroy$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private location: Location
  ) {
    const customNavigationClient = new MsalCustomNavigationClient(
      this.authService,
      this.router,
      this.location
    );
    this.authService.instance.setNavigationClient(customNavigationClient);
  }

  ngOnInit(): void {
    // eslint-disable-next-line no-undef
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) =>
            status === InteractionStatus.AcquireToken
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (message: EventMessage) =>
            message.eventType === EventType.ACCOUNT_ADDED ||
            message.eventType === EventType.ACCOUNT_REMOVED ||
            message.eventType === EventType.LOGIN_SUCCESS ||
            message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({ eventType, payload }: EventMessage) => {
          if (
            !this.authService.instance.getAllAccounts().length &&
            (eventType === EventType.ACCOUNT_ADDED ||
              eventType === EventType.ACCOUNT_REMOVED)
          ) {
            if (!this.authService.instance.getAllAccounts().length) {
              window.location.pathname = '/';
            }
          }
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (message: EventMessage) =>
            message.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({ eventType, error }: EventMessage) => {
          if (
            eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
            error &&
            error['errorMessage'].includes('AADB2C90077')
          ) {
            const accessTokenRequest = {
              scopes: [
                `openid profile email offline_access ${environment.azureB2C.clientId}`,
              ],
              account: this.authService.instance.getActiveAccount(),
            } as RedirectRequest;

            this.authService
              .acquireTokenRedirect(accessTokenRequest)
              .pipe(takeUntil(this.destroy$))
              .subscribe();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  private checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
}
