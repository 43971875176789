import { Injectable } from '@angular/core';
import {
  fromEvent,
  merge,
  Observable,
  Subject,
  Subscription,
  timer
} from 'rxjs';
import { INACTIVITY_TIMER_EVENTS } from 'src/app/shell/data-access/constants/inactivity-timer-events';
import { TimeUtil } from '../../../../shared/utils/time';

@Injectable({ providedIn: 'root' })
export class InactivityTimerService {
  private mergedEvents$!: Observable<Event>;
  private pageEventsSubscription = new Subscription();
  private inactivityTimer = new Subscription();
  private totalTimeOutInMilliSeconds!: number;

  public expired = new Subject<boolean>();

  /**
   * Initializes the inactivity timer and returns an observable once the timer has elapsed
   * @param {number} timeOutInMinutes - The number of minutes the inactivity timer needs to run
   * @returns Observable<boolean>
   */
  public startMonitoring(timeOutInMinutes: number): Observable<boolean> {
    this.mergedEvents$ = this.getMergedEventsObservable();
    this.totalTimeOutInMilliSeconds = TimeUtil.convertSecondsToMilliSeconds(
      TimeUtil.convertMinutesToSeconds(timeOutInMinutes)
    );
    this.pageEventsSubscription = this.mergedEvents$.subscribe(this.resetTimer);
    this.startTimer();

    return this.expired;
  }

  /**
   * Restarts the timer once an active user event has been triggered
   */
  public resetTimer = (): void => {
    this.inactivityTimer.unsubscribe();

    if (this.pageEventsSubscription.closed) {
      this.pageEventsSubscription = this.mergedEvents$.subscribe(
        this.resetTimer
      );
    }

    this.startTimer();
  };

  /**
   * Stops the timer
   */
  public stopTimer(): void {
    this.inactivityTimer.unsubscribe();
    this.pageEventsSubscription.unsubscribe();
  }

  /**
   * Starts the timer
   */
  private startTimer(): void {
    this.inactivityTimer = timer(this.totalTimeOutInMilliSeconds).subscribe(
      () => this.expired.next(true)
    );
  }

  /**
   * Gets all the user active events from config and merges them into one event observable
   * @returns Observable<Event>
   */
  private getMergedEventsObservable(): Observable<Event> {
    const observableArray$ = INACTIVITY_TIMER_EVENTS.reduce(
      (accumulator: Observable<Event>[], currentValue) => {
        accumulator.push(fromEvent(currentValue.scope, currentValue.event));
        return accumulator;
      },
      []
    );

    return merge(...observableArray$);
  }
}
