import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { ToasterStatus } from '../../data-access/enums/toaster-status.enum';
import { IToasterCustomData } from '../../data-access/models/toaster-config.model';

@Component({
  selector: 'laps-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
  readonly ToasterStatus = ToasterStatus;

  constructor(
    public snackBarRef: MatSnackBarRef<ToasterComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public customData: IToasterCustomData
  ) {}
}
