import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../enums/role.enum';
import { LookupFacade } from '../facades/lookup.facade';
import { ILookupFields } from '../state/lookup-data/entities/lookup-fields.entity';

@Injectable({
  providedIn: 'root',
})
export class LookupResolver implements Resolve<ILookupFields> {
  constructor(private lookupFacade: LookupFacade) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ILookupFields> {
    if (route.data?.['roles'][0] === Role.ADMIN) {
      return this.lookupFacade.adminLookupData$;
    }
    return this.lookupFacade.lookupData$;
  }
}
